@import 'assets/styles/core/settings';

.is-agree {
  color: $dark-gray;
  font-size: rem-calc(12);

  a {
    text-decoration: underline;
  }
}
