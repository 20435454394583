@import 'assets/styles/core';

.company-card {
  $root: &;

  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 1rem 1rem .5rem;
  min-height: 10rem;
  border: 1px solid $medium-gray;
  border-radius: $global-radius;

  @include breakpoint(small only) {
    padding: $global-padding;
    min-height: 0;
    align-items: center;

    h3 {
      padding: 0;
      background-color: #fff;
      border-top: none;
      border-bottom: none;
      font-size: rem-calc(14);
      line-height: 1.3;
    }
  }

  &__favorite {
    position: absolute;
    top: .75rem;
    right: .75rem;
    display: none;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;

    & svg {
      fill: $white;
    }

    &_chosen {
      display: block;

      & svg {
        fill: $tangerine-yellow;
      }
    }
  }

  &__recomend {
    background-image: url('../../../../../../styles/catalog/svg/recomend-status.svg');
    position: absolute;
    bottom: rem-calc(6);
    right: 1rem;
    width: rem-calc(80);
    height: 1.5rem;
    background-repeat: no-repeat;

    @include breakpoint(small only) {
      bottom: rem-calc(5);
      width: rem-calc(50);
      height: rem-calc(13);
    }
  }

  &__logo {
    max-width: 8rem;
    margin: 1rem 1rem 1rem 0;

    @include breakpoint(small only) {
      max-width: 5rem;
      margin: 0 $global-margin 0 0;
    }

    @media (width <= 320px) {
      display: none;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
  }

  &__name {
    font-size: rem-calc(18);
    font-weight: $global-weight-bold;
    margin-bottom: .5rem;
  }

  &__status {
    margin-left: .5rem;
    margin-bottom: .5rem;
  }

  &__header {
    display: flex;
    align-items: center;

    @include breakpoint(small only) {
      justify-content: space-between;
    }
  }

  &:hover {
    & #{$root}__favorite {
      display: block;
    }
  }
}
